import { FC, ReactNode } from 'react';
import MultiCarousel, {
  CarouselProps,
  ResponsiveType,
} from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { SHARED_PALETTE } from '@lib/theme/constants';
import { SxStyles } from '@lib/theme/sxTheme';
import { SxTheme } from '@lib/theme/types';
import { Box } from '@mui/material';

import { CarouselArrows, CarouselArrowsProps } from './CarouselArrows';
import { CarouselDot, CarouselDotProps } from './CarouselDot';
import { CAROUSEL_BREAKPOINTS } from './constants';
import { DeviceType } from './types';

type Props = (Omit<CarouselProps, 'ssr' | 'deviceType' | 'responsive'> & {
  children: ReactNode;
  responsive?: ResponsiveType;
  leftArrowProps?: CarouselArrowsProps['leftArrowProps'];
  rightArrowProps?: CarouselArrowsProps['rightArrowProps'];
  dotProps?: CarouselDotProps;
  sx?: SxTheme;
}) &
  (
    | {
        ssr: true;
        deviceType?: DeviceType;
      }
    | {
        ssr: false;
        deviceType?: undefined;
      }
  );

export const Carousel: FC<Props> = (props) => {
  const {
    children,
    sx,
    ssr,
    leftArrowProps,
    rightArrowProps,
    slidesToSlide = 1,
    dotProps,
    infinite = true,
    deviceType = 'desktop',
    ...rest
  } = props;

  return (
    <Box sx={styles.merge('root', sx)}>
      <MultiCarousel
        ssr={ssr}
        deviceType={deviceType}
        arrows={false}
        slidesToSlide={slidesToSlide}
        renderButtonGroupOutside
        customButtonGroup={
          <CarouselArrows
            leftArrowProps={leftArrowProps}
            rightArrowProps={rightArrowProps}
            isInfinite={infinite}
            slidesToSlide={slidesToSlide}
          />
        }
        customDot={<CarouselDot {...dotProps} />}
        draggable
        swipeable
        customTransition="transform 260ms ease-in-out"
        transitionDuration={260}
        infinite={infinite}
        keyBoardControl
        minimumTouchDrag={80}
        responsive={CAROUSEL_BREAKPOINTS}
        {...rest}
      >
        {children}
      </MultiCarousel>
    </Box>
  );
};

const styles = new SxStyles({
  root: {
    position: 'relative',

    '.react-multi-carousel-list': {
      display: 'grid', // Fix for jumpy images on initial load
    },
    '.react-multi-carousel-dot-list': {
      mb: 2,
      border: 'none',

      '& > li button': {
        border: `1px solid ${SHARED_PALETTE.lightGrey}`,
      },
    },
  },
});
