import { FC } from 'react';

import { SxStyles } from '@lib/theme/sxTheme';
import { Box } from '@mui/material';
import { CarouselSlide } from '@ui/gallery/carousel/CarouselSlide';
import { DynamicImage } from '@ui/gallery/DynamicImage';

import { ImageListItem } from '../types';

interface Props {
  item: ImageListItem;
}

export const Slide: FC<Props> = (props) => {
  const { item } = props;
  const dynamicStyles = styles({
    imageBackgroundColor: item.backgroundColor,
  });

  return (
    <CarouselSlide
      elevation={0}
      sx={dynamicStyles.getValue('root')}
      contentProps={{ sx: dynamicStyles.getValue('rootContainer') }}
    >
      <Box sx={dynamicStyles.getValue('imageContainer')}>
        {item?.background?.url && (
          <DynamicImage
            src={item.background.url}
            alt={item.background.alt ?? ''}
            width={100}
            height={100}
            objectFit={item.imageFit ?? undefined}
            containerProps={{ sx: dynamicStyles.getValue('image') }}
          />
        )}
      </Box>
    </CarouselSlide>
  );
};

interface StyleProps {
  imageBackgroundColor: ImageListItem['backgroundColor'];
}

const styles = ({ imageBackgroundColor }: StyleProps) =>
  new SxStyles({
    root: {
      margin: (theme) => theme.spacing(0, 2),
    },
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: 160,
      padding: 0,

      '&:last-child': {
        paddingBottom: 0,
      },
    },
    imageContainer: {
      display: 'flex',
      flexShrink: 0,
      width: 'inherit',
      height: 'inherit',
      backgroundColor: imageBackgroundColor,
    },
    image: {
      pointerEvents: 'none',
    },
  });
