import { FC } from 'react';

import { SxStyles } from '@lib/theme/sxTheme';
import { Carousel as CustomCarousel } from '@ui/gallery/carousel/Carousel';

import { ImageListCarouselVariation } from '../types';
import { RESPONSIVE_CAROUSEL } from './constants';
import { Slide } from './Slide';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';

interface Props {
  slice: ImageListCarouselVariation;
}

export const ImageListCarousel: FC<Props> = (props) => {
  const { slice } = props;
  const {
    arrowColor,
    infinite,
    autoPlay,
    autoPlaySpeed,
    dotColor,
    backgroundColor,
    paddingTop,
    paddingBottom,
    width,
  } = slice.primary;
  const dynamicStyles = styles({ arrowColor });

  return (
    <SliceLayout
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
      contentProps={{ sx: dynamicStyles.getValue('layout') }}
    >
      <CustomCarousel
        ssr
        responsive={RESPONSIVE_CAROUSEL}
        infinite={infinite ?? false}
        autoPlay={autoPlay ?? false}
        autoPlaySpeed={autoPlaySpeed ?? undefined}
        showDots
        leftArrowProps={{
          sx: dynamicStyles.merge('arrow', 'leftArrow'),
        }}
        rightArrowProps={{
          sx: dynamicStyles.merge('arrow', 'rightArrow'),
        }}
        dotProps={{ activeColor: dotColor ?? undefined }}
        sx={dynamicStyles.getValue('carousel')}
        renderDotsOutside
      >
        {slice.items.map((item, index) => (
          <Slide key={index} item={item} />
        ))}
      </CustomCarousel>
    </SliceLayout>
  );
};

interface StyleProps {
  arrowColor: ImageListCarouselVariation['primary']['arrowColor'];
}

const styles = ({ arrowColor }: StyleProps) =>
  new SxStyles({
    layout: {
      overflow: 'hidden',
    },
    carousel: {
      margin: (theme) => theme.spacing(0, -2),

      '.react-multi-carousel-list': {
        display: 'grid', // Fix for jumpy images on initial load
        margin: 0,
      },
      '.react-multi-carousel-dot-list': {
        marginBottom: (theme) => theme.spacing(1),
      },
    },
    arrow: {
      color: arrowColor,

      svg: {
        fill: (theme) => arrowColor && theme.palette[arrowColor],
      },
    },
    leftArrow: {
      left: {
        xs: -3,
      },
    },
    rightArrow: {
      right: {
        xs: -3,
      },
    },
  });
