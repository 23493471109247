import { FC } from 'react';

import { DefaultCarousel } from './default-slice/DefaultCarousel';
import { ImageListCarousel } from './image-list/ImageListCarousel';
import {
  CarouselVariations,
  DefaultCarouselVariation,
  ImageListCarouselVariation,
} from './types';

export interface CarouselSliceProps {
  slice: CarouselVariations;
}

export const CarouselSlice: FC<CarouselSliceProps> = ({ slice }) => {
  switch (slice.variation) {
    case 'image-list':
      return <ImageListCarousel slice={slice as ImageListCarouselVariation} />;
    default:
      return <DefaultCarousel slice={slice as DefaultCarouselVariation} />;
  }
};
