import { FC } from 'react';

import { SxStyles } from '@lib/theme/sxTheme';
import { Carousel as CustomCarousel } from '@ui/gallery/carousel/Carousel';

import { DefaultCarouselVariation } from '../types';
import { RESPONSIVE_CAROUSEL } from './constants';
import { FullWidthSlide } from './FullWidthSlide';
import { HalfHalfSlide } from './HalfHalfSlide';
import { TiledSlide } from './TiledSlide';

interface Props {
  slice: DefaultCarouselVariation;
}

export const DefaultCarousel: FC<Props> = ({ slice }) => {
  const { arrowColor, infinite, autoPlay, autoPlaySpeed, dotColor } =
    slice.primary;
  const dynamicStyles = styles({ arrowColor });

  return (
    <CustomCarousel
      ssr
      responsive={RESPONSIVE_CAROUSEL}
      showDots
      infinite={infinite ?? false}
      autoPlay={autoPlay ?? false}
      autoPlaySpeed={autoPlaySpeed ?? undefined}
      leftArrowProps={{ sx: dynamicStyles.getValue('arrow') }}
      rightArrowProps={{ sx: dynamicStyles.getValue('arrow') }}
      dotProps={{ activeColor: dotColor ?? undefined }}
      sx={dynamicStyles.getValue('carousel')}
    >
      {slice.items.map((item, index) => {
        switch (item.slideVariation) {
          case 'half-half':
            return <HalfHalfSlide key={index} {...item} />;
          case 'tiled':
            return <TiledSlide key={index} {...item} />;
          default:
            return <FullWidthSlide key={index} {...item} />;
        }
      })}
    </CustomCarousel>
  );
};

interface StyleProps {
  arrowColor: DefaultCarouselVariation['primary']['arrowColor'];
}

const styles = ({ arrowColor }: StyleProps) =>
  new SxStyles({
    carousel: {
      '.react-multi-carousel-list': {
        display: 'grid', // Fix for jumpy images on initial load
        margin: 0,
      },
    },
    arrow: {
      color: arrowColor,

      svg: {
        fill: (theme) => arrowColor && theme.palette[arrowColor],
      },
    },
  });
