import { ResponsiveType } from 'react-multi-carousel';

export const RESPONSIVE_CAROUSEL: ResponsiveType = {
  xs: {
    breakpoint: {
      max: 600,
      min: 0,
    },
    items: 1,
  },
  sm: {
    breakpoint: {
      max: 960,
      min: 600,
    },
    items: 2,
  },
  md: {
    breakpoint: {
      max: 1280,
      min: 960,
    },
    items: 3,
  },
  lg: {
    breakpoint: {
      max: 3000,
      min: 1280,
    },
    items: 5,
  },
};
