import { FC } from 'react';

import { hasRichTextContent } from '@lib/slice-machine/richText';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Grid } from '@mui/material';
import { ContentSpacer } from '@templates/common/layout/ContentSpacer';
import { DynamicImage } from '@ui/gallery/DynamicImage';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';

import { DefaultSliceItem } from '../types';

type Props = DefaultSliceItem;

export const TiledSlide: FC<Props> = (props) => {
  const {
    background,
    backgroundColor,
    imageFit,
    leftTile,
    rightTile,
    leftTileBackground,
    rightTileBackground,
  } = props;

  return (
    <Box sx={styles.getValue('root')} bgcolor={backgroundColor ?? undefined}>
      {background?.url && (
        <Box sx={styles.getValue('imageContainer')}>
          <DynamicImage
            src={background.url}
            alt={background.alt ?? ''}
            fill
            objectFit={imageFit ?? undefined}
            containerProps={{ sx: styles.getValue('image') }}
          />
        </Box>
      )}
      <ContentSpacer
        isPadded={false}
        sx={styles.getValue('contentWrapper')}
        contentProps={{ sx: { width: '100%' } }}
      >
        <Grid
          sx={styles.getValue('content')}
          direction={{ xs: 'column', md: 'row' }}
          container
        >
          {hasRichTextContent(leftTile) && (
            <Grid item xs={6}>
              <Box
                sx={styles.getValue('tile')}
                bgcolor={leftTileBackground ?? undefined}
              >
                <CustomRichText render={leftTile} />
              </Box>
            </Grid>
          )}
          {hasRichTextContent(rightTile) && (
            <Grid item xs={6}>
              <Box
                sx={styles.getValue('tile')}
                bgcolor={rightTileBackground ?? undefined}
              >
                <CustomRichText render={rightTile} />
              </Box>
            </Grid>
          )}
        </Grid>
      </ContentSpacer>
    </Box>
  );
};

const styles = new SxStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  imageContainer: {
    width: '100%',
    minHeight: 550,
    height: '100%',
    position: 'relative',
  },
  tile: {
    display: 'flex',
    width: '100%',
    height: '100%',
    p: 2,
  },
  image: {
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
  },
  contentWrapper: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%,-50%)',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    fontSize: 25,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    '& * p': {
      margin: 0,
    },
  },
});
