import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { DotProps } from 'react-multi-carousel';

import { TextColor } from '@lib/slice-machine/types';
import { SxStyles } from '@lib/theme/sxTheme';
import { SxTheme } from '@lib/theme/types';
import { IconButton, ListItem } from '@mui/material';

export interface CarouselDotProps extends DotProps {
  sx?: SxTheme;
  activeColor?: TextColor;
}

export const CarouselDot: FC<CarouselDotProps> = (props) => {
  const {
    onClick,
    carouselState,
    active = false,
    activeColor = 'dark.main',
    sx,
  } = props;
  const { t } = useTranslation();
  const dynamicStyles = styles({ active, activeColor });
  const isOnlyDot = carouselState?.totalItems === carouselState?.slidesToShow;

  if (isOnlyDot) {
    return null;
  }

  return (
    <ListItem sx={dynamicStyles.getValue('root')} onClick={handleClick}>
      <IconButton
        sx={dynamicStyles.merge('dot', sx)}
        aria-label={t('common:ariaLabel.prevSlide')}
        onClick={handleClick}
      />
    </ListItem>
  );

  function handleClick(): void {
    if (onClick) {
      onClick();
    }
  }
};

interface StyleProps {
  active: boolean;
  activeColor: TextColor;
}

const styles = ({ active, activeColor }: StyleProps) =>
  new SxStyles({
    root: {
      width: 15,
      height: 15,
      padding: (theme) => theme.spacing(0, 3, 0, 0),
    },
    dot: {
      width: 'inherit',
      height: 'inherit',
      padding: 0,
      boxShadow: 1,
      backgroundColor: active ? activeColor : 'initial',

      '&:hover': {
        backgroundColor: activeColor,
      },
    },
  });
