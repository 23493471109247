import { FC, ReactNode } from 'react';

import { SxStyles } from '@lib/theme/sxTheme';
import {
  Box,
  Card,
  CardContent,
  CardContentProps,
  CardProps,
} from '@mui/material';

interface Props extends CardProps {
  children: ReactNode;
  contentProps?: CardContentProps;
}

export const CarouselSlide: FC<Props> = (props) => {
  const { children, contentProps, ...rest } = props;

  return (
    <Card sx={styles.getValue('root')} variant="elevation" {...rest}>
      <CardContent
        component={Box}
        display="flex"
        width="100%"
        {...contentProps}
      >
        {children}
      </CardContent>
    </Card>
  );
};

const styles = new SxStyles({
  root: {
    display: 'flex',
    height: '100%',
    margin: (theme) => theme.spacing(0, 1),
    backgroundColor: 'background.paper',
  },
});
