import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { ButtonGroupProps } from 'react-multi-carousel';

import { SxStyles } from '@lib/theme/sxTheme';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { Box, IconButton, IconButtonProps } from '@mui/material';
import { ContentSpacer } from '@templates/common/layout/ContentSpacer';

export type CarouselArrowsProps = ButtonGroupProps & {
  isInfinite: boolean;
  slidesToSlide: number;
  leftArrowProps?: IconButtonProps;
  rightArrowProps?: IconButtonProps;
};

export const CarouselArrows: FC<CarouselArrowsProps> = (props) => {
  const {
    carouselState,
    previous,
    next,
    isInfinite,
    slidesToSlide,
    leftArrowProps = {},
    rightArrowProps = {},
  } = props;
  const { sx: leftArrowSx, ...leftArrowRestProps } = leftArrowProps;
  const { sx: rightArrowSx, ...rightArrowRestProps } = rightArrowProps;
  const { t } = useTranslation();
  const memoCalc = useCallback(() => {
    let isNotFirstSlide = isInfinite;
    let isNotLastSlide = isInfinite;
    let shouldReset = false;

    if (
      carouselState?.totalItems !== undefined &&
      carouselState?.slidesToShow !== undefined &&
      carouselState?.currentSlide !== undefined &&
      !isInfinite
    ) {
      const remainder = carouselState.totalItems - carouselState.slidesToShow;
      const numberOfDots =
        !remainder || remainder <= 0
          ? 1
          : 1 + Math.ceil(remainder / slidesToSlide);

      isNotLastSlide = carouselState.currentSlide + 1 !== numberOfDots;
      isNotFirstSlide = carouselState.currentSlide !== 0;
      shouldReset = numberOfDots < carouselState.currentSlide + 1;
    }

    return { isNotFirstSlide, isNotLastSlide, shouldReset };
  }, [
    carouselState?.totalItems,
    carouselState?.slidesToShow,
    carouselState?.currentSlide,
    isInfinite,
    slidesToSlide,
  ]);
  const { isNotFirstSlide, isNotLastSlide, shouldReset } = memoCalc();

  useEffect(() => {
    // Fixes a bug in finite mode where if you resize carousel to, let's say,
    // mobile size then scroll to last slide and resize back to desktop size
    // there's empty slides on the right side.
    if (shouldReset && previous && !isInfinite) {
      previous();
    }
  }, [shouldReset, previous, isInfinite]);

  return (
    <ContentSpacer
      isPadded={false}
      sx={styles.getValue('container')}
      contentProps={{ sx: styles.getValue('containerContent') }}
    >
      {isNotFirstSlide && (
        <IconButton
          sx={styles.merge<false>('arrowBtnBase', 'leftArrow', leftArrowSx)}
          size="small"
          aria-label={t('common:ariaLabel.prevSlide')}
          onClick={previous}
          {...leftArrowRestProps}
        >
          <Box
            sx={styles.getValue('wrapper')}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <KeyboardArrowLeftIcon fontSize="medium" />
          </Box>
        </IconButton>
      )}
      {isNotLastSlide && (
        <IconButton
          sx={styles.merge<false>('arrowBtnBase', 'rightArrow', rightArrowSx)}
          size="small"
          aria-label={t('common:ariaLabel.nextSlide')}
          onClick={next}
          {...rightArrowRestProps}
        >
          <Box
            sx={styles.getValue('wrapper')}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <KeyboardArrowRightIcon fontSize="medium" />
          </Box>
        </IconButton>
      )}
    </ContentSpacer>
  );
};

const styles = new SxStyles({
  arrowBtnBase: {
    pointerEvents: 'all',
    position: 'absolute',
    display: 'block',
    backgroundColor: 'light.main',
    boxShadow: 1,

    '&:hover': {
      backgroundColor: 'light.main',
    },
  },
  leftArrow: {
    left: 5,
  },
  rightArrow: {
    right: 5,
  },
  container: {
    position: 'absolute',
    height: '100%',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)',
    pointerEvents: 'none',
  },
  containerContent: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  wrapper: {
    width: 30,
    height: 30,
  },
});
